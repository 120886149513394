body {
    width: 100vw;
    height: 100vh;
}

.mainContainer{
    display: grid;
    grid-template-columns: 1fr 6fr;
    height: calc(100vh - 1.5em);
}

header{
    background:#17252A;
    font-size:1em; 
    color: #feffff;
    text-align:center;
    height: 1.5em;    
}

aside{
    background-color: #3aafa9;
    grid-column: 1;
}

main{
    background-color: #feffff;
    grid-column: 2;
}

.nav-link{
    color: #feffff !important;
}

.nav-link.active{
    background-color: #feffff;
    color: #17252A !important;
}

.navBar{
    display: flex;
    flex-direction: column;
}

.date{
    grid-column: 2;
    font-size: 0.8em;
    color: gray;
}

.secondary{
    font-size: 0.8em;
    color: gray;
}

p{
    margin: 0 !important;
}

.addMealImage{
    height: 2em;
}

.meal{
    border: none !important;
    padding: 0em;
    text-align: center;
    height: 400px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.meal > .container {
    padding-right: 0em;
    height: 100%;
}

.meal > .container > .row {
    height: 100%;
}

.meal > .container > .row > *{
    height: 100%;
}

.recipeCard{
    text-align: center;
    max-width: 300px;
    max-height: 400px;
}

.badgeContainer{
    display: flex;
    flex-wrap: wrap;
}

.recipeFilter{
    margin-bottom: 1em;
}

.recipesList{
    padding-top: 2em;
    display: flex;
    flex-wrap: wrap;
}
/**** DETAIL VIEW ***/

.fields{
    position: relative;
}

.inlineField{
    display: flex;
    height: 2.5em;
    justify-content: space-between;
    align-items: center;
}

.inlineField h6{
    margin: 0;
}

.inlineField .form-control{
    width: 10em;
    max-width: 10em ;
}

.inlineField .invalid-feedback{
    position: absolute;
    bottom: -1.2em;
    left: 0;
}

.detailView{
    padding-top: 1em;
    padding-bottom: 1em;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
}

/****SIDE CARD***/
.sideCard{
    width: 100%;
    min-width: 280px;
    max-width: 400px;
    height: 100%;
    background-color: #3aafa9;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 60% auto auto auto;
    grid-template-areas:
        "image"
        "title"
        "body"
        "button";
}

.sideCard > img{
    grid-area: image;
    width: 100%;
    height: 100%;
}
.sideCard > .title{
    grid-area: title;
    color: white;
    margin: 0.7em;
}
.sideCard > .body{
    grid-area: body;
    margin: 0.7em;
}
.sideCard > .button {
    grid-area: button;
}

.emptySidecard{
    width: 100%;
    height: 100%;
    background-color: lightgray;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.emptySidecard > div{
    width: 120px;
}

.emptySidecard > div:hover{
    width: 150px;
}

.emptySidecard img{
    cursor: pointer;
}

/**** MEAL FROM ****/
.mealForm{
    width: 100%;
    height: 100%;
}

/*** Aggregator ***/
.aggregator{
    text-align: center;
}

/*** LIST ITEM ****/
.listItem{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0;
}

.listItem h6{
    margin-bottom: 0.2em;
}

.listItem p{
    margin: 0;
    color: gray;
    max-height: 1.5em;
    overflow: hidden;
}

.listItem .leftSide{
    grid-column: 1;
}

.listItem .rightSide{
    grid-column: 2;
}

/*** aggregator - detail ***/

.aggregatorHeader{
    background-color: #def2f1;
    padding: 0.5em;
    height: 3.2em;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
}

/*** User Panel ***/
.userPanel{
    background-color: lightgray;
    height: 100%;
}

/*** Log Out ***/
.logOut:hover{
    transform: scale(1.2);
}

/*** Household filter ***/
.householdFilter h6{
    min-width: 9em;
}


/*** Authentication ***/
.authenticateBackground{
    background-color: lightgray;
    height: 100vh;
    width: 100vw;
}

.authenticateBackground img {
    opacity: 0.6;
    height: 100%;
    width: 100%;
}

.authenticatePanel{
    position: absolute;
    left:25%;
    top:25%;
    background-color: #def2f1;
    width: 50%;
    height: 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.authenticatePanel form{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.authenticatePanel form > div {
    width: 60%;
}

.invalid-feedback{
    margin:0 !important;
}

.authenticatePanel form div,button{
    margin-bottom: 1em;
}

.authenticateHeader {
    width: 100%;
    display: flex;
    height: 3.5em;
    justify-content: space-around;
}

.layoutHolder {
    width: 100%;
    height: calc(100% - 4em);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.navTab {
    width: 100%;
    height: 100%;
    padding: 1em;
    text-align: center;
    background-color: lightgray;
    cursor: pointer;
}

.navTab:hover{
    background-color: #def2f1;
}

.navTab.left{
    box-shadow: inset -3px -3px 8px rgba(0, 0, 0, 0.2);
}

.navTab.right{
    box-shadow: inset 3px -3px 8px rgba(0, 0, 0, 0.2);
}

.navTab.selected{
    box-shadow: none;
    background-color: #def2f1;
}